/** @format */

import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { map, Observable, of } from 'rxjs';
import { SubscriptionModel, SubscriptionPriceModel } from '../models';
import { catchError } from 'rxjs/operators';

@Injectable()
export class SubscriptionService {
  constructor(private apiService: ApiService) {}

  postOrder(coupon_code?: string): Observable<string> {
    return this.apiService
      .post('/v1/subscriptions/order', { coupon_code })
      .pipe(map((response: any) => response.data.order_uuid));
  }

  getPrice(): Observable<SubscriptionPriceModel> {
    return this.apiService.get('/v2/subscriptions/price').pipe(
      map((response: any) => response.data),
      catchError((error: any) => {
        return of(error);
      })
    );
  }

  getSubscriptions(): Observable<SubscriptionModel | null> {
    return this.apiService
      .get('/v1/subscriptions')
      .pipe(map((response: any) => response.data.subscription));
  }

  postSubscriptionCancel(): Observable<SubscriptionModel> {
    return this.apiService
      .post('/v1/subscriptions/cancel')
      .pipe(map((response: any) => response.data));
  }

  postSubscriptionRenew(): Observable<SubscriptionModel> {
    return this.apiService
      .post('/v1/subscriptions/renew')
      .pipe(map((response: any) => response.data));
  }
}
